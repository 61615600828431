<template>
    <pagination
        :options={}
        :records=totalRecords
        :per-page=10
        :value=selectedPage
        @input="page => selectedPage = page"/>
</template>

<script>
import Pagination from 'vue-pagination-2'
export default {
    name: "MyPagination",
    components: {
        Pagination
    },
    data(){
        return {
            selectedPage: 1,
            totalRecords: 0
        }
    }
}
</script>