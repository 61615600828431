<template src="./fxaccount.html"></template>
<style scoped src="./fxaccount">

</style>
<script>
import fxService from '@/services/FXServices.vue'
import fxConstants from '@/constants/FxConstants.vue'
import Loading from 'vue-loading-overlay'
import modal from '@/components/modal/notification/modal'
import pagination from '@/components/vtable/vtpagination.vue'
import appConstants from '@/constants/AppConstants.vue'

export default {
    components: {
        Loading, modal, pagination
    },
    data() {
        return {
            fxAcc: [],
            filteredFxAccounts: [],
            fxProvider: JSON.parse(JSON.stringify(fxConstants.fxProvider)),
            selectedFXProvider: '',
            loaderVisible: false,
            columns: ['FXAccountId', 'FXProviderName', 'Currency', 'CurrentActualBalance', 'CurrentPendingBalance'],
            options: {
                headings: {
                    FXAccountId: 'FX Account Id',
                    FXProviderName: 'FX  Provider Name',
                    CurrentActualBalance: 'Current Actual Balance',
                    CurrentPendingBalance: 'Current Pending Balance'
                }
            },
            alertObject: {
                errorCode: null,
                errorDescription: null,
                errorDetails: []
            },
            enableLazyLoading: appConstants.enableLazyLoading
        }
    },
    methods: {
        showAlert(code, description) {
            this.alertObject.errorCode = code
            this.alertObject.errorDescription = description
            this.alertObject.errorDetails = []
            this.$bvModal.show(this.$refs.childModal.id)
        },
        filterFxAccounts() {
            this.filteredFxAccounts = this.selectedFXProvider === '' ? this.fxAcc
                : this.fxAcc.filter(item => item.FXProviderName._text === this.selectedFXProvider)
            if (this.enableLazyLoading)
                this.$refs.paginationRef.selectedPage = Math.ceil(this.filterFxAccounts.length / 10) === 0 ? 1 : Math.ceil(this.filterFxAccounts.length / 10)
        },
        responseMapping(data) {
            if (data) {
                data = Array.isArray(data) ? data : [data]
                const reciptient = (recipt) => {
                    Object.values(recipt).map((el, key) => {
                        const Objectkey = Object.keys(recipt)[key]
                        recipt[Objectkey] = el._text
                    })
                }
                data.map(reciptient)
            } else {
                data = []
            }
            return data
        },
    },
    computed: {
        fxAccounts() {
            let fxAccounts = []
            if (this.fxAcc) {
                fxAccounts = JSON.parse(JSON.stringify(this.filteredFxAccounts))
                if (this.enableLazyLoading) {
                    let selectedPage = this.$refs.paginationRef?.selectedPage
                    fxAccounts = fxAccounts.slice((selectedPage - 1) * 10, selectedPage * 10)
                }
                fxAccounts = this.responseMapping(JSON.parse(JSON.stringify(fxAccounts)))
            }
            const updateTotalRecords = () => {
                if(this.$refs.paginationRef) {
                    this.$refs.paginationRef.totalRecords = this.filteredFxAccounts?.length ?? 0
                }
            }
            this.enableLazyLoading ? updateTotalRecords() : null
            return fxAccounts
        }
    },
    async created() {
        try {
            this.loaderVisible = true
            this.fxProvider.forEach(el => {
                if (el.value !== '') {
                    el.value = el.text
                } else {
                    el.text = 'All'
                }
            })
            await fxService.methods.GetFXAccountList(this.selectedFXProvider)
                .then(res => {
                    this.fxAcc = res.csFxGetAccountsResponse.FxAccountList.FXAccountListItem
                    if(this.fxAcc) {
                        this.fxAcc = Array.isArray(this.fxAcc) ? this.fxAcc : [this.fxAcc]
                    }else{
                        this.fxAcc = []
                    }
                    this.filteredFxAccounts = JSON.parse(JSON.stringify(this.fxAcc))
                })
                .catch(err => {
                    this.alertObject = err
                    this.$bvModal.show(this.$refs.childModal.id)
                })
            this.loaderVisible = false
        } catch (error) {
            this.loaderVisible = false
        }
    }
}
</script>