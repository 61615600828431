<script>
export default {
    fxProvider : [
        { text : '-- Select Provider --', value: '' },
        { text : 'BDO', value: '1' },
        { text : 'IndusInd', value: '2'},
        { text : 'CurrencyCloud', value: '3'},
        { text : 'Cargills', value: '4'},
        { text : 'BOP', value: '5'},
        { text : 'RoyalExchange_Cash', value: '6'},
        { text : 'Tranglo_UnionPay', value: '8'},
        { text : 'Tranglo_BankAccount', value: '9'},
        { text : 'YesBank', value: '10'}
        
    ],
    fxTransactionType : [
        { text : '--Select--' , value : null  },
        { text : 'Funding' , value : '1'  },
        { text : 'FundFXTradePrefunding' , value : '2'  },
        { text : 'FXTradeSingleTransaction' , value : '3'  },
        { text : 'Payment' , value : '4'  },
        { text : 'Adjustment' , value : '5'  }
    ],
    fxTransactionStatus : [
        { text : '--Select' , value : null  },
        { text : 'Completed' , value : '1'  },
        { text : 'Queued' , value : '2'  },
        { text : 'TradePendingPayment' , value : '3'  },
        { text : 'TradePendingExecution' , value : '4'  },
        { text : 'PaymentPending' , value : '5'  },
        { text : 'Failed' , value : '6'  },
        { text : 'Cancelled' , value : '7'  },
        { text : 'Error' , value : '8'  }
    ]   
}
</script>